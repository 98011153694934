import { useEffect, useState } from 'react';
import axios from 'axios';
import moment from 'moment';

function App({ set_step, is_loading, set_is_loading, is_connected, set_is_connected }) {

  const [is_qr_visible, set_is_qr_visible] = useState(false)
  const [qr, set_qr] = useState('')
  const [id, set_id] = useState('')
  const [use_effect, set_use_effect] = useState(false)

  useEffect(() => {
    CheckConnexion()
  }, [use_effect])

  const NewSessionQr = async () => {
    const id_temp = 'C212L' + (new moment()).format('YYYYMMDDhhmmss');
    set_id(id_temp);
    // set_id('' + (new moment()).format('YYYYMMDDhhmmss'));
    set_is_loading(true)
    await axios
      .post(`${process.env.REACT_APP_API_URL}sessions/add`, {
        id: id_temp,
        isLegacy: false,
      })
      .then((res) => {
        if (res?.data?.success === false) alert(res?.data?.message)
        else {
          set_qr(res?.data?.data?.qr)
          set_is_qr_visible(true)
          localStorage.setItem('id', id_temp);
        }
        // console.log(res?.data?.message)
        // console.log(res?.data)
      })
      .catch((err) => {
        // console.log(process.env.REACT_APP_API_URL + `sessions/add`)
        // console.log(err)
        // console.log(err?.message)
        alert(err?.message)
      })
    set_is_loading(false)
  }

  const CheckConnexion = async () => {
    set_is_loading(true)
    await axios
      .get(`${process.env.REACT_APP_API_URL}sessions/find/${localStorage.getItem('id')}`)
      .then((res) => {
        if (res?.data?.success === false) return false
        else {
          set_is_connected(true)
          set_step(2)
        }
      })
      .catch((err) => {
        console.error(err?.message)
        // alert(err?.message)
      })
    set_is_loading(false)
  }

  // const IsConnected = async () => {
  //   const ident = localStorage.getItem('id');
  //   const isConnect = ident != null && ident != "" && ident != undefined
  //   if (id == '') set_id(ident)
  //   else {
  //     setTimeout(() => {
  //       console.log('CheckConnexion')
  //       // return CheckConnexion()
  //     }, 15000)
  //   }
  //   // if (isConnect) set_step(2);
  //   console.log(isConnect);
  //   return isConnect;
  // }

  return (
    <>
      {/* Step1 <br></br> */}
      {!is_loading && is_connected && 'Online'}
      {!is_loading && !is_connected && 'Offline'}
      {!is_loading && !is_qr_visible && !is_connected && <button type="button" style={mystyle} onClick={NewSessionQr}>Connect</button>}
      {is_loading && 'Loading...'}
      {!is_loading && is_qr_visible && !is_connected && <img src={qr} alt={qr} style={mystyle} />}
      <br></br>
      {!is_loading && is_qr_visible && is_connected && <button type="button" style={mystyle} onClick={CheckConnexion}>Check Connexion</button>}

      {/* Step1 <br></br>
      {<button type="button" style={mystyle} onClick={NewSessionQr}>Connect</button>}
      {is_loading && 'Loading...'}
      {<img src={qr} alt={qr} style={mystyle} />}
      <br></br>
      {<button type="button" style={mystyle} onClick={CheckConnexion}>Check Connexion</button>} */}
    </>
  );
}

export default App;

const mystyle = {
  color: "white",
  backgroundColor: "DodgerBlue",
  padding: "10px",
  fontFamily: "Arial",

  display: 'block',
  marginLeft: 'auto',
  marginRight: 'auto',
};
import { useEffect, useState } from 'react';
import axios from 'axios';
import moment from 'moment';

function App({ set_is_loading }) {

  const [separator, set_separator] = useState('')
  const [numbers, set_numbers] = useState('')
  const [message, set_message] = useState('')
  const [numbers_match, set_numbers_match] = useState([])

  function filterPhoneNumber(phoneNumber) {
    return phoneNumber.replace(/[^\d]/g, '');
  }

  const CheckNumbers = () => {
    // const phoneNumbers = numbers.match(/[\+]?([0-9]?)([0-9][0-9][ ]|[ ][0-9][0-9]){4,6}/g)
    // const phoneNumbers = numbers.match(/[\+]?([0-9][\s]?|[0-9]?)([0-9][0-9][\s]){4,6}/g)
    const phoneNumbers = numbers.match(/[\+]?([0-9][\s]?|[0-9]?)([(][0-9]{3}[)][\s]?|[0-9]{3}[-\s\.]?)[0-9]{3}[-\s\.]?[0-9]{4,6}/g)
    // console.log(phoneNumbers.map(filterPhoneNumber))
    // console.log(phoneNumbers?.length > 0)
    // if (phoneNumbers?.length > 0) set_numbers_match(phoneNumbers.map(filterPhoneNumber))
  }

  const SendMessage = async () => {
    // /chats/send-bulk?id=C212L20230101095707

    // const phoneNumbers = numbers.match(/[\+]?([0-9][\s]?|[0-9]?)([(][0-9]{3}[)][\s]?|[0-9]{3}[-\s\.]?)[0-9]{3}[-\s\.]?[0-9]{4,6}/g)
    // console.log(phoneNumbers.map(filterPhoneNumber))
    // set_numbers_match(phoneNumbers.map(filterPhoneNumber))

    // const id_temp = 'C212L' + (new moment()).format('YYYYMMDDhhmmss');
    // set_id(id_temp);
    // set_id('' + (new moment()).format('YYYYMMDDhhmmss'));
    // if (phoneNumbers?.length > 0) set_numbers_match(phoneNumbers.map(filterPhoneNumber))

    const phoneNumbers = numbers.match(/[\+]?([0-9][\s]?|[0-9]?)([(][0-9]{3}[)][\s]?|[0-9]{3}[-\s\.]?)[0-9]{3}[-\s\.]?[0-9]{4,6}/g)
    if (phoneNumbers?.length > 0) set_numbers_match(phoneNumbers.map(filterPhoneNumber))
    // console.log(
    //   phoneNumbers.map(filterPhoneNumber).map(
    //     (num) => (
    //       console.log({
    //         'receiver': num,
    //         'message': {
    //           'text': message
    //         }
    //       })

    //     )
    //   )
    // );
    // console.log(
    //   phoneNumbers.map(filterPhoneNumber).map((num) => ({
    //     'receiver': num,
    //     'message': {
    //       'text': message
    //     }
    //   }))
    // );

    set_is_loading(true)
    await axios
      .post(`${process.env.REACT_APP_API_URL}chats/send-bulk?id=${localStorage.getItem('id')}`,
        phoneNumbers.map(filterPhoneNumber).map((num) => ({
          'receiver': num,
          'message': {
            'text': message
          }
        }))
      )
      .then((res) => {
        console.log('good');
      })
      .catch((err) => {
        alert(err?.message)
      })
    set_is_loading(false)
  }

  return (
    <>
      Chat
      <br></br>
      <br></br>

      {/* Separator:
      <br></br>
      <input onChange={(event) => { set_separator(event.target.value) }}></input>
      <br></br>
      <br></br> */}

      Numbers:
      <br></br>
      <textarea onChange={(event) => { set_numbers(event.target.value) }} name="Text1" cols="40" rows="1"></textarea>
      <br></br>
      <br></br>

      Message:
      <br></br>
      <textarea onChange={(event) => { set_message(event.target.value) }} name="Text1" cols="40" rows="4"></textarea>

      <br></br>
      <br></br>
      <button style={mystyle} onClick={CheckNumbers}>Check Numbers</button>
      <br></br>
      <button style={mystyle} onClick={SendMessage}>Send Message</button>

      {numbers_match && numbers_match?.length > 0 &&
        <br></br>
      }

      {numbers_match && numbers_match?.length > 0 && numbers_match.map((num, i) =>
        <>
          <br></br>
          <span key={i} style={{ fontSize: 8 }}>{i}: {num}</span>
        </>
      )}

    </>
  );
}

export default App;

const mystyle = {
  color: "white",
  backgroundColor: "DodgerBlue",
  padding: "10px",
  fontFamily: "Arial",

  display: 'block',
  marginLeft: 'auto',
  marginRight: 'auto',
};
// import logo from './logo.svg';
// import './App.css';
import { useState } from 'react';
import Connect from './Connect';
import Chat from './Chat';
import SendMessage from './SendMessage';

function App() {

  const [step, set_step] = useState(1)
  const [is_loading, set_is_loading] = useState(false)
  const [is_connected, set_is_connected] = useState(false)
  const num = 0;

  return (
    <>
      {!is_loading && is_connected && ('Step ' + step)}
      {!is_loading && is_connected && <br></br>}

      {!is_connected &&
        <Connect
          set_step={set_step}
          set_is_loading={set_is_loading}
          is_loading={is_loading}
          is_connected={is_connected}
          set_is_connected={set_is_connected}
        />
      }
      {is_connected &&
        <Chat
          set_is_loading={set_is_loading}
        />
      }
    </>
    // <div className="App">
    //   <header className="App-header">
    //     <img src={logo} className="App-logo" alt="logo" />
    //     <p>
    //       Edit <code>src/App.js</code> and save to reload.
    //     </p>
    //     <a
    //       className="App-link"
    //       href="https://reactjs.org"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //     >
    //       Learn React
    //     </a>
    //   </header>
    // </div>
  );
}

export default App;
